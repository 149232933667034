import {NavLink} from 'react-router-dom';

function Servicios() {
return (
<div>





    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 pt-3 justify-items-center">

        <div className="card w-80 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
                <img src="https://images.unsplash.com/photo-1621560222298-0824faf9add5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" alt="Softlanding" className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center">
                <h2 className="text-blue-900 font-bold text-xl text-center pt-4" style={{ color: `#282a59;` }}>Soft
                    Landing</h2>
                <p className=" lowercase text-justify">Inteligencia de mercado . Debida diligencia . Constitución de
                    empresas . Filiales . Asuntos migratorios . Relaciones bancarias . Análisis arancelario . Apoyo
                    en
                    procesos de contratación . Apoyo inmobiliario . Amenities ejecutivas</p>
                <div className="card-actions">
                    <NavLink className="rounded-md p-2 bg-blue-700 hover:bg-slate-200 hover:text-gray-900 hover:shadow-lg text-slate-200" to="/contacto">Contacto</NavLink>
                </div>
            </div>
        </div>


        <div className="card w-80 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
                <img src="https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Asuntos Públicos" className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center">
                <h2 className="text-blue-900 font-bold text-xl text-center pt-4" style={{ color: `#282a59;` }}>Asuntos Públicos</h2>
                <p className=" lowercase text-justify">Monitoreo legislativo y administrativo . Relaciones con Gobiernos . Trámites complejos . Relaciones legislativas . Mapeo de actores clave . Imagen pública corporativa</p>
                <div className="card-actions">
                    <NavLink className="rounded-md p-2 bg-blue-700 hover:bg-slate-200 hover:text-gray-900 hover:shadow-lg text-slate-200" to="/contacto">Contacto</NavLink>
                </div>
            </div>
        </div>

        <div className="card w-80 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
                <img src="https://images.unsplash.com/photo-1523875194681-bedd468c58bf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80" alt="Relacionamiento estrategico" className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center">
                <h2 className="text-blue-900 font-bold text-xl text-center pt-4" style={{ color: `#282a59;` }}>Relacionamiento Estratégico</h2>
                <p className=" lowercase text-justify">Vinculación estratégica con actores clave . Construcción reputacional y de imagen pública en nuevos mercados . Facilitación de procesos complejos . Pertenencia a espacios de influencia . Vinculación comercial y empresarial</p>
                <div className="card-actions">
                    <NavLink className="rounded-md p-2 bg-blue-700 hover:bg-slate-200 hover:text-gray-900 hover:shadow-lg text-slate-200" to="/contacto">Contacto</NavLink>
                </div>
            </div>
        </div>


        <div className="card w-80 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
                <img src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Comunicación Estrategica" className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center">
                <h2 className="text-blue-900 font-bold text-xl text-center pt-4" style={{ color: `#282a59;` }}>Comunicación Estratégica</h2>
                <p className=" lowercase text-justify">Construcción local de marca y estructura mediática reputacional . Free press .Atención comunicacional de crisis . Manejo de comunicación tradicional y digital con enfoque local . Vinculación con medios y Voceros locales . Campañas y eventos</p>
                <div className="card-actions">
                    <NavLink className="rounded-md p-2 bg-blue-700 hover:bg-slate-200 hover:text-gray-900 hover:shadow-lg text-slate-200" to="/contacto">Contacto</NavLink>
                </div>
            </div>
        </div>

        <div className="card w-80 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
                <img src="https://images.unsplash.com/photo-1584931423298-c576fda54bd2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Control de Riesgos y Prevención de Crisis" className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center">
                <h2 className="text-blue-900 font-bold text-xl text-center pt-4" style={{ color: `#282a59;` }}>Control de Riesgos y Prevención de Crisis</h2>
                <p className=" lowercase text-justify">Identificación y mitigación oportuna de riesgos a la operación . Prevención de crisis que presenten riesgo a las operaciones . Atención inmediata de amenazas a la continuidad de la operación . Restitución reputacional</p>
                <div className="card-actions">
                    <NavLink className="rounded-md p-2 bg-blue-700 hover:bg-slate-200 hover:text-gray-900 hover:shadow-lg text-slate-200" to="/contacto">Contacto</NavLink>
                </div>
            </div>
        </div>


        <div className="card w-80 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
                <img src="https://images.unsplash.com/photo-1554769945-af468c934022?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80" alt="Sustentabilidad Social y Comunitaria." className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center">
                <h2 className="text-blue-900 font-bold text-xl text-center pt-4" style={{ color: `#282a59;` }}>Sustentabilidad Social y Comunitaria.</h2>
                <p className=" lowercase text-justify">Relaciones comunitarias . Acompañamiento con grupos de presión . Estudios de impacto . Administración comunitaria . Diseño de programas de responsabilidad y sustentabilidad social</p>
                <div className="card-actions">
                    <NavLink className="rounded-md p-2 bg-blue-700 hover:bg-slate-200 hover:text-gray-900 hover:shadow-lg text-slate-200" to="/contacto">Contacto</NavLink>
                </div>
            </div>
        </div>



    </div>




</div>
)
}

export default Servicios