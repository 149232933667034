import HubspotForm from 'react-hubspot-form'
import React from 'react'

function Contacto() {
return (
<div>

    <div className="card lg:card-side bg-base-100 shadow-xl">
        <figure><img src="https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Contacto" /></figure>
        <div className="card-body">
            <HubspotForm portalId='19586452' formId='ee70f755-855d-41d3-8030-5f177f7df802' onSubmit={()=>
                console.log('Enviar!')}
                onReady={(form) => console.log('Formulario listo!')}
                loading={<div>Cargando...</div>}
                />
        </div>
    </div>


</div>
)
}

export default Contacto