import { BrowserRouter, NavLink, Route, Routes } from 'react-router-dom';
import './App.css';
import Contacto from './components/Contacto';
import Inicio from './components/Inicio';
import Nosotros from './components/Nosotros';
import Servicios from './components/Servicios';
 
function App() {
  return (

    <>


      <BrowserRouter>

        {/* Debe ir el menu de navegacion */}
        {/* Ejemplo de navlink  <NavLink to="/servicios" className="mr-4 hover:font-bold md:mr-6">Servicios</NavLink> */}

        <nav className="bg-white border-gray-200 dark:bg-gray-900 sticky">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-md px-4 md:px-6 py-2.5">
            <NavLink to="/" className="flex items-center"> <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">DraconisCorp</span></NavLink>
            <div className="flex items-center">
              <NavLink to="tel:+525554529114" className="mr-6 text-sm font-medium text-gray-500 dark:text-white hover:text-blue-500 " target={'_blank'}><i class="fa-solid fa-phone"></i> +52 55 5452 9114 </NavLink>
            </div>
          </div>
        </nav>
        <nav className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-50">
          <div className="max-w-screen-md px-4 py-3 mx-auto md:px-6">
            <div className="flex items-center">
              <ul className="flex flex-row mt-0 mr-6 space-x-8 text-sm font-medium">
                <li>
                  <NavLink to="/" className="text-gray-900 dark:text-white hover:font-bold" aria-current="page">Inicio</NavLink>
                </li>
                <li>
                  <NavLink to="/servicios" className="text-gray-900 dark:text-white hover:font-bold">Servicios</NavLink>
                </li>
                <li>
                  <NavLink to="/nosotros" className="text-gray-900 dark:text-white hover:font-bold">Nosotros</NavLink>
                </li>
                <li>
                  <NavLink to="/contacto" className="text-gray-900 dark:text-white hover:font-bold">Contacto</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>


        
        <Routes>

          {/* Ejemplo de  Route completa con componentes <Route path="/contacto" element={<Contacto />} /> */}
          <Route path="/" element={<Inicio />} />
          <Route path="/servicios" element={<Servicios />}/>
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/contacto" element={<Contacto />}/>
          <Route path="*" element={<Inicio />}/>
        </Routes>


        {/* Debe ir le footer */}


        <footer className="footer p-10 bg-base-200 text-base-content footer-center grid grid-cols-1 md:grid-cols-3">
          <div>
            <span className="footer-title color_draconis">Nuestras empresas</span>
            <NavLink className="text-gray-900 dark:text-white hover:font-bold" to="/nosotros">HEXXDRA</NavLink>
            <NavLink className="text-gray-900 dark:text-white hover:font-bold" to="/nosotros">VIOSSYS</NavLink>
            <NavLink className="text-gray-900 dark:text-white hover:font-bold" to="/nosotros">DREAMSSTAGE</NavLink>
            <NavLink className="text-gray-900 dark:text-white hover:font-bold" to="/nosotros">CAJA DE ESTRELLAS</NavLink>
          </div>
          <div>
            <span className="footer-title">Compañia</span>
            <NavLink className="text-gray-900 dark:text-white hover:font-bold" to="/nosotros">Clientes</NavLink>
            <NavLink className="text-gray-900 dark:text-white hover:font-bold" to="/nosotros">Empresa</NavLink>
            <NavLink className="text-gray-900 dark:text-white hover:font-bold" to="mailto:contacto@draconiscorp.comm">Correo</NavLink>
            <NavLink className="text-gray-900 dark:text-white hover:font-bold" to="tel:+525554529114">Teléfono +52 55 54529114</NavLink>
          </div>
          <div>
            <span className="footer-title ">Nuestras oficinas</span>
            <NavLink className="color_draconis dark:text-white font-bold" to="https://goo.gl/maps/XqdVMxhBUb9kfbbX6">México ● Virgilio 9 ● Polanco ● 11550 ● CDMX</NavLink>
            <NavLink className="color_draconis dark:text-white font-bold" to="">Colombia ● Kra 19 120-75 ● Bogotá</NavLink>
            <NavLink className="color_draconis dark:text-white font-bold" to="https://goo.gl/maps/jNgFBJRbhVinEA3Z6">Bolivia ● Dra María de Boland 3107 ● Santa Cruz de la Sierra</NavLink>
          </div>
        </footer>








      </BrowserRouter>



    </>


  );
}

export default App;
