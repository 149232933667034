import React from 'react'

function Nosotros() {
    return (
        <div>


            <div className="grid grid-rows-1 mx-5 md:mx-60">

                <div>
                    <div className="avatar grid justify-center p-3">
                        <div className="w-32 h-32 rounded">
                            <img src="https://draconiscorp.com/src/logo-dragon-draconis.webp" />
                        </div>
                    </div>
                    <h3 className="text-blue-900 font-bold text-xl text-center pt-4" style={{ color: `#282a59;` }}>¡Somos
                        Proveedores de Soluciones!</h3>
                    <p className="text-justify p-3 mx-auto">DraconisCorp no es una empresa, sino una fábrica de soluciones a la medida de su organización, sea pública, privada o social. Somos un conglomerado de empresas dedicadas a ofrecer soluciones estratégicas y aumentar su rentabilidad en distintos sectores de especialidad. Por eso decimos que en DraconisCorp ¡...somos Proveedores de Soluciones! y decenas de casos de éxito nos respaldan.</p>
                </div>
            </div>

            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <h2 className="text-blue-900 font-bold text-xl text-center pt-4" style={{ color: `#282a59;` }}>¡Algunos de Nuestros socios comerciales!</h2>
                    <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/1.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/2.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/3.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain sm:col-start-2 lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/4.png" />
                        <img className="col-span-2 col-start-2 max-h-18 w-full object-contain sm:col-start-auto lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/5.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/6.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/7.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/8.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain sm:col-start-2 lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/9.png" />
                        <img className="col-span-2 col-start-2 max-h-18 w-full object-contain sm:col-start-auto lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/10.png"/>
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/11.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/12.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/13.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain sm:col-start-2 lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/14.png" />
                        <img className="col-span-2 col-start-2 max-h-18 w-full object-contain sm:col-start-auto lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/15.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/16.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/17.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/18.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain sm:col-start-2 lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/19.png" />
                        <img className="col-span-2 col-start-2 max-h-18 w-full object-contain sm:col-start-auto lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/20.png"  />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/21.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/22.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/23.png" />
                        <img className="col-span-2 max-h-18 w-full object-contain sm:col-start-2 lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/24.png"/>
                        <img className="col-span-2 col-start-2 max-h-18 w-full object-contain sm:col-start-auto lg:col-span-1 grayscale" src="https://draconiscorp.com/src/logos/25.png" />
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Nosotros