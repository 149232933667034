import React from 'react'
import {NavLink} from 'react-router-dom';

function Inicio() {
return (
<div>

    <div className="hero min-h-screen" style={{ backgroundImage:
        `url("https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`
        }}>
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="hero-content text-center text-neutral-content">
            <div className="max-w-md">
                <div className="avatar">
                    <div className="w-24 rounded">
                        <img src="https://draconiscorp.com/src/logo-dragon-draconis.webp" />
                    </div>
                </div>
                <h1 className="mb-5 text-5xl font-bold">We are Solutions Providers!</h1>
                    <NavLink className="rounded-md p-3 bg-blue-700 hover:bg-slate-200 hover:text-gray-900" to="/contacto">Contacto</NavLink>
            </div>
        </div>
    </div>


    <div className="grid grid-rows-1 ">
        <div style={{color:`#282a59;`}} className="">
            <h2 className="text-blue-900 font-bold text-xl text-center pt-6">Nuestra presencia</h2>
            <p className="text-center p-2 mx-auto">México - Colombia - Guatemala - El Salvador - Honduras - Costa Rica -
                Panamá - República Dominicana -
                Ecuador - Perú - Bolivia - Paraguay - Chile - Argentina - Mercado Hispano y Estados Unidos.</p>
        </div>
    </div>


    <div className="grid grid-rows-1 mx-5 md:mx-60">

        <div>
            <div className="avatar grid justify-center p-3">
                <div className="w-32 h-32 rounded">
                    <img src="https://draconiscorp.com/src/logo-dragon-draconis.webp" />
                </div>
            </div>
            <p className="text-justify p-3 mx-auto">DraconisCorp no es una empresa, sino una fábrica de soluciones a la
                medida de su organización, sea pública, privada o social. Somos un conglomerado de empresas dedicadas a
                ofrecer soluciones estratégicas en distintos sectores de especialidad, por eso decimos que en
                DraconisCorp…</p>
            <h3 className="text-blue-900 font-bold text-xl text-center pt-4" style={{color:`#282a59;`}}>¡Somos
                Proveedores de Soluciones!</h3>
            <p className="text-justify p-3 mx-auto">Nuestro equipo de profesionales con más de 30 años de experiencia en
                todos los mercados de América Latina y el Caribe brinda acertadas estrategias legales, financieras,
                administrativas, regulatorias, comerciales, mercadológicas, mediáticas, de relacionamiento estratégico y
                comunicacionales, utilizando recursos y metodologías propias y probadas.</p>
        </div>
    </div>

    <h3 className="text-blue-900 font-bold text-2xl text-center uppercase pt-4" style={{color:`#282a59;`}}>Nuestras Empresas</h3>
    <div className="hero min-h-fit" style={{ backgroundImage:
        `url("https://images.unsplash.com/photo-1528314997032-4300ddb60769?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`
        }}>
        <div className="hero-overlay bg-opacity-20"></div>



        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 py-6">

            <div className="card w-80 glass py-6">
                <figure><img src="https://draconiscorp.com/src/logo-hexxdra.webp" alt="Hexxdra" /></figure>
                <div className="card-body">
                    <h2 className="card-title">Hexxdra</h2>
                    <p className="text-justify">Ingeniería estratégica en internacionalización de empresas en América Latina y el Caribe.</p>
                </div>
            </div>

            <div className="card w-80 glass py-6">
                <figure><img src="https://draconiscorp.com/src/viossys.webp" alt="Viossys" /></figure>
                <div className="card-body">
                    <h2 className="card-title">Viossys</h2>
                    <p className="text-justify">Alianzas y soluciones tecnológicas para facilitar procesos y elevar la eficiencia y rentabilidad de las organizaciones.</p>
                </div>
            </div>

            <div className="card w-80 glass py-6">
                <figure><img src="https://draconiscorp.com/src/dreamsstage-purpura.webp" alt="DreamsStage" /></figure>
                <div className="card-body">
                    <h2 className="card-title">DreamsStage</h2>
                    <p className="text-justify">Nuestro espacio de desarrollo e inversión en nuevas empresas, diseñadas para atender los retos más complejos de la humanidad.</p>
                </div>
            </div>

            <div className="card w-80 glass py-6">
                <figure><img src="https://draconiscorp.com/src/cajadeestrellas.jpeg" alt="Caja de Estrellas" /></figure>
                <div className="card-body">
                    <h2 className="card-title">Caja de Estrellas</h2>
                    <p className="text-justify">Nuestro espacio filantrópico para dar tanto como recibimos, a través de la fotografía.</p>
                </div>
            </div>
        </div>


    </div>




</div>
)
}

export default Inicio